import React, { useCallback } from "react";
import { Unity, useUnityContext } from "react-unity-webgl";
import Stack from "react-bootstrap/Stack";
import Image from 'react-bootstrap/Image'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'

import { useParams, useLocation, useNavigate, useNavigationType, use } from "react-router-dom";

import { useEffect, useState } from "react";

import api from '../services/api'
import unityApi from '../services/unityApi'

import './Game.css'

const GameStatus = {
    INVALID: 'invalid',
    LOGIN: "login",
    NOUSER: "nouser",
    RUNNING: "running",
}

function Game() {
    let { userid } = useParams();
    let location = useLocation();
    let navigate = useNavigate();
    let [user, setUser] = useState({});
    const navigationType = useNavigationType();

    const [showSaveNotification, setShowSaveNotification] = useState(false);

    const [status, setStatus] = useState(GameStatus.INVALID);

    const [isUsingTouch, setIsUsingTouch] = useState(false);

    //https://react-unity-webgl.dev/docs/api/cache-control
    const { unityProvider, 
        requestFullscreen, 
        sendMessage, 
        isLoaded: hasUnityFinishedLoading, 
        UNSAFE__unityInstance: unityInstance, 
        loadingProgression, 
        unload } = 
    useUnityContext({
        loaderUrl: process.env.PUBLIC_URL + "/game/Build/Client.loader.js",
        dataUrl: process.env.PUBLIC_URL + "/game/Build/Client.data",
        frameworkUrl: process.env.PUBLIC_URL + "/game/Build/Client.framework.js",
        codeUrl: process.env.PUBLIC_URL + "/game/Build/Client.wasm",
        streamingAssetsUrl: process.env.PUBLIC_URL + "/game/StreamingAssets/",
        //cacheControl: (url) => "must-revalidate",
    });

    const serverURL = process.env.REACT_APP_GAME_SERVER_TEST_URL || (window.location.hostname +  "/server");

    const startLogin = useCallback(() =>
    {
        console.log("StartingLogin");
        console.log(user);
        sendMessage("NetworkManager", "ConnectToServer", unityApi.createConnectionPackage(serverURL, user.server.port, user.id));
    }, [user, sendMessage]);

    useEffect(() => {
        console.log("Initializing Game");

        if (status === GameStatus.INVALID) {
            setStatus(GameStatus.LOGIN);

            if (location.state != null) {
                setShowSaveNotification(location.state.numLogins < 1);
                console.log(location.state);
                setUser(location.state);
                setStatus(GameStatus.RUNNING);
            } else {
                api.get('/User?userid=' + userid).then(response => {
                    if (response) {
                        console.log("got user")
                        console.log(response);
                        setStatus(GameStatus.RUNNING);
                        setShowSaveNotification(response.data.numLogins < 1);
                        setUser(response.data);
                        
                        if (hasUnityFinishedLoading) {
                            startLogin();
                        }
                    } else {
                        console.log("could not get user")
                        setStatus(GameStatus.NOUSER);
                    }

                }).catch(reason => {
                    console.log("error when getting user")
                    setStatus(GameStatus.NOUSER);
                    //setStatus(GameStatus.RUNNING);
                });
            }
        }
    }, [hasUnityFinishedLoading, location.state, navigate, startLogin, status, userid]);


    useEffect(() => {
        if (status === GameStatus.RUNNING) {
            console.log("FinishedLoading, starting game");
            startLogin();
        }
    }, [status, startLogin]);


    useEffect(() => {
        return () => {
            if (unityInstance != null) {
               // await unityInstance.Quit();
                //detachAndUnloadImmediate();
                console.log("Unloading game...");
                unload();
            }
        }
    }, [unityInstance, unload]);

    useEffect(() =>  {
        if (navigationType == "PUSH") {
            window.location.reload();
        }
    }, [navigationType]);

    useEffect(() => {    
        window.onpopstate = () => {
            console.log("pop state")
            window.location.reload();
        }
     }, []);

    React.useEffect(() => {
        const handleTouchUsed = () => {
            setIsUsingTouch(true);
        };

        document.addEventListener('touchstart', handleTouchUsed);

        return () => {
            document.removeEventListener('touchstart', handleTouchUsed);
        };
    }, []);

    function getGameDiv() {
        if (status === GameStatus.INVALID || status === GameStatus.LOGIN) {
            return (
                <div className="StatusMessage">
                    Einloggen...
                </div>
            );
        } 
        else if (status === GameStatus.NOUSER) {
            return (
                <div className="StatusMessage">
                    User existiert nicht. Bitte probiere eine andere ID.
                </div>
            );
        }
        else if (status === GameStatus.RUNNING) {
            return ( 
            <div className="GameContainer">
                <div className="Game">
                    <Unity unityProvider={unityProvider} className="UnityFrame"/>
                    <div className="LoadingOverlay" style={{ display: hasUnityFinishedLoading ? "none" : "block" }}>
                        <p id="DownloadText">Loading Game...</p>
                        <div id="ProgressBarEmpty" style={{ background: "url('/game/TemplateData/progress-bar-empty-light.png') no-repeat center" }}>
                            <div id="ProgressBarFull" style={{ width: loadingProgression * 100 + "%", background: "url('/game/TemplateData/progress-bar-full-light.png') no-repeat center" }}/>
                        </div>
                    </div>
                </div>
                <div className="GameBottomRow">
                    {/* <Button className="FullscreenButton" onClick={() => requestFullscreen(true)}>
                        <Image className="FullscreenImage" src={process.env.PUBLIC_URL + "/game/TemplateData/fullscreen-button.png"}></Image>
                        <div className="FullscreenText">Fullscreen</div>
                    </Button> */}
                </div> 
            </div>
            );
        } else {
            return (<div className="StatusMessage"></div>)
        }
    }

    return (
        <Stack className="GameStack">
            { getGameDiv() }
            {/* <div>
                {status}
            </div> */}
            { isUsingTouch && 
                <div>
                    <p>Warnung: Diese app wurde für Desktop Browser mit Maus und Tastatur entwickelt. Mobile Geräte und Touch werden aktuell nicht unterstützt.</p>
                </div>
            }
            <div>
                <Modal show={showSaveNotification} fullscreen={true} 
                onHide={() => setShowSaveNotification(false)} 
                onExited={() => setShowSaveNotification(false)}>
                    <Modal.Dialog >
                        <Modal.Header closeButton>
                            <Modal.Title>Nicht vergessen die Seite zu deinen Lesezeichen hinzuzufügen!</Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                            <p>Kopiere die URL oder füge die Seite zu deinen Lesezeichen hinzu um später weiter zu machen (die Nummer ist deine ID).</p>
                        </Modal.Body>

                        <Modal.Footer>
                            <Button variant="primary" onClick={() => setShowSaveNotification(false)}>Verstanden!</Button>
                        </Modal.Footer>
                    </Modal.Dialog>
                </Modal>
            </div>
        </Stack>
    );
}

export default Game;