class ConnectionInfo {
constructor(serverIp, serverPort, userId) {
        this.serverIp = serverIp;
        this.serverPort = serverPort;
        this.userId = userId;
    }

    serialize() 
    {
        return JSON.stringify(this);
    }
};

const unityApi = {
    createConnectionPackage: function(serverIp, serverPort, userId)
    {
        return new ConnectionInfo(serverIp, serverPort, userId).serialize();
    }
};


export default unityApi;