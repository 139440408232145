import Button from 'react-bootstrap/Button'
import Stack from 'react-bootstrap/Stack'
import './UserAccountEntryPoint.css';

import { useNavigate } from 'react-router-dom'

function Welcome() {
    let navigation = useNavigate()

    function navigate(path) {
        navigation(path);
    }

    return (
        <div className='Welcome'>
            <Stack className='WelcomeStack'>
            <h1 className='WelcomeHeader'>Please select whether your are a new user or a recurring user:</h1>
                <Button className='Button' onClick={() => navigate('/NewUser')}>NewUser</Button>
                <Button className='Button' onClick={() => navigate('/ExistingUser')}>Returning</Button>
            </Stack>
        </div>
    );
}

export default Welcome;