import Button from 'react-bootstrap/Button'
import Stack from 'react-bootstrap/Stack'
import './PrivacyPolicy.css';


function PrivacyPolicy() {
    return (
        <div className='PrivacyPolicy'>
            <h1>Terms of Use</h1>
            <p>These terms and conditions outline the rules and regulations for the use of this project's website.</p>
            <strong>The project is located in:</strong><br />
            <address>8010 Graz<br />Austria<br />
            </address>

            <p>By accessing this website we assume you accept these terms and conditions in full. Do not continue to use the website
                if you do not accept all of the terms and conditions stated on this page.</p>
            <p>The following terminology applies to these Terms and Conditions, Privacy Statement and Disclaimer Notice
                and any or all Agreements: “Client”, “You” and “Your” refers to you, the person accessing this website
                and accepting the terms and conditions. “The Project, “Ourselves”, “We”, “Our” and “Us”, refers
                to the project. “Party”, “Parties”, or “Us”, refers to both the Client and ourselves, or either the Client
                or ourselves. Any use of the above terminology or other words in the singular, plural,
                capitalisation and/or he/she or they, are taken as interchangeable and therefore as referring to same.</p>
            <h3>License</h3>
            <p>Unless otherwise stated, the Open Reassembly project and/or it’s licensors own the intellectual property rights for
                all material on this website. All intellectual property rights are reserved. You may view and/or print
                pages from <strong>https://openreassembly.cgv.tugraz.at</strong> for your own personal use subject to restrictions set in these terms and conditions.</p>
            <p>You must not:</p>
            <ol>
                <li>Republish material from <strong>https://openreassembly.cgv.tugraz.at</strong></li>
                <li>Sell, rent or sub-license material from <strong>https://openreassembly.cgv.tugraz.at</strong></li>
                <li>Reproduce, duplicate or copy material from <strong>https://openreassembly.cgv.tugraz.at</strong></li>
            </ol>
            <p>Redistribute content from the Open Reassembly project (unless content is specifically made for redistribution).</p>
            <h3>Hyperlinking to our Content</h3>
            <ol>
                <li>The following organizations may link to our website without prior written approval:
                    <ol>
                        <li>Government agencies;</li>
                        <li>Search engines;</li>
                        <li>News organizations;</li>
                        <li>Online directory distributors when they list us in the directory may link to our website in the same
                            manner as they hyperlink to the websites of other listed businesses; and</li>
                        <li>Systemwide Accredited Businesses except soliciting non-profit organizations, charity shopping malls,
                            and charity fundraising groups which may not hyperlink to our website.</li>
                    </ol>
                </li>
            </ol>
            <ol start="2">
                <li>These organizations may link to our home page, to publications or to other website information so long
                    as the link: (a) is not in any way misleading; (b) does not falsely imply sponsorship, endorsement or
                    approval of the linking party and its products or services; and (c) fits within the context of the linking
                    party's site.
                </li>
            </ol>

            <p>Approved organizations may hyperlink to our website as follows:</p>
            <ol>
                <li>By use of our project name; or</li>
                <li>By use of the uniform resource locator (Web address) being linked to; or</li>
                <li>By use of any other description of our website or material being linked to that makes sense within the
                    context and format of content on the linking party's site.</li>
            </ol>
            <p>No use of Open Reassembly's logo or other artwork will be allowed for linking absent a trademark license
                agreement.</p>

            <h3>iframes</h3>
            <p>Without prior approval and express written permission, you may not create frames around our Web pages or
                use other techniques that alter in any way the visual presentation or appearance of our website.</p>
            <h3>Reservation of Rights</h3>
            <p>We reserve the right at any time and in its sole discretion to request that you remove all links or any particular
                link to our website. You agree to immediately remove all links to our website upon such request. We also
                reserve the right to amend these terms and conditions and its linking policy at any time. By continuing
                to link to our website, you agree to be bound to and abide by these linking terms and conditions.</p>
            <h3>Removal of links from our website</h3>
            <p>If you find any link on our website or any linked website objectionable for any reason, you may contact
                us about this. We will consider requests to remove links but will have no obligation to do so or to respond
                directly to you.</p>
            <p>Whilst we endeavour to ensure that the information on this website is correct, we do not warrant its completeness
                or accuracy; nor do we commit to ensuring that the website remains available or that the material on the
                website is kept up to date.</p>
            <h3>Content Liability</h3>
            <p>We shall have no responsibility or liability for any content appearing on your website. You agree to indemnify
                and defend us against all claims arising out of or based upon your Website. No link(s) may appear on any
                page on your website or within any context containing content or materials that may be interpreted as
                libelous, obscene or criminal, or which infringes, otherwise violates, or advocates the infringement or
                other violation of, any third party rights.</p>
            <h3>Disclaimer</h3>
            <p>To the maximum extent permitted by applicable law, we exclude all representations, warranties and conditions relating to our website and the use of this website (including, without limitation, any warranties implied by law in respect of satisfactory quality, fitness for purpose and/or the use of reasonable care and skill). Nothing in this disclaimer will:</p>
            <ol>
                <li>limit or exclude our or your liability for death or personal injury resulting from negligence;</li>
                <li>limit or exclude our or your liability for fraud or fraudulent misrepresentation;</li>
                <li>limit any of our or your liabilities in any way that is not permitted under applicable law; or</li>
                <li>exclude any of our or your liabilities that may not be excluded under applicable law.</li>
            </ol>
            <p>The limitations and exclusions of liability set out in this Section and elsewhere in this disclaimer: (a)
                are subject to the preceding paragraph; and (b) govern all liabilities arising under the disclaimer or
                in relation to the subject matter of this disclaimer, including liabilities arising in contract, in tort
                (including negligence) and for breach of statutory duty.</p>
            <p>To the extent that the website and the information and services on the website are provided free of charge,
                we will not be liable for any loss or damage of any nature.</p>
            
            <br/>
            <br/>


            <a name="privacyPolicy"></a>
            <div id="privacyPolicyAnchor">
            </div>
            
            <h1>Privacy Policy</h1>
            <h3>Your privacy is critically important to us.</h3>

            <p>It is this project's policy to respect your privacy regarding any information we may collect while operating our website.
                This Privacy Policy applies to <a href="https://openreassembly.cgv.tugraz.at">https://openreassembly.cgv.tugraz.at</a> (hereinafter, "us", "we", or "https://openreassembly.cgv.tugraz.at").
                We respect your privacy and are committed to protecting personally identifiable information you may provide us through the Website.
                We have adopted this privacy policy ("Privacy Policy") to explain what information may be collected on our Website, how we use this information,
                and under what circumstances we may disclose the information to third parties.
                This Privacy Policy applies only to information we collect through the Website and does not apply to our collection of information from other sources.</p>
            <p>This Privacy Policy, together with the Terms and conditions posted on our Website, set forth the general rules and policies governing your use of our Website.
                Depending on your activities when visiting our Website, you may be required to agree to additional terms and conditions.</p>

            <h3>Website Visitors</h3>
            <p>Like most website operators, we collect non-personally-identifying information of the sort that web browsers and servers typically make available,
                such as the browser type, language preference, referring site, and the date and time of each visitor request.
                Our purpose in collecting non-personally identifying information is to better understand how visitors use this website.
            </p>
            <p>We temporarily collect potentially personally-identifying Internet Protocol (IP) addresses.
                This information is gathered in temporary server logs, which are used to ensure the continuous functionality of this site and deleted after several weeks. We do not collect permanent personally-identifying data in any other form.
            </p>

            <h3>Security</h3>
            <p>The security of your Personal Information is important to us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure.
                While we strive to use commercially acceptable means to protect your Personal Information, we cannot guarantee its absolute security.</p>



            <h3>Links To External Sites</h3>
            <p>Our Service may contain links to external sites that are not operated by us. If you click on a third party link, you will be directed to that third party's site.
                We strongly advise you to review the Privacy Policy and terms and conditions of every site you visit.</p>
            <p>We have no control over, and assume no responsibility for the content, privacy policies or practices of any third party sites, products or services.</p>


            <h3>Cookies</h3>
            <p>We do not use cookies on this website.</p>

            <h3>Privacy Policy Changes</h3>
            <p>Although most changes are likely to be minor, we may change this Privacy Policy from time to time, and at our sole discretion.
                We encourage visitors to frequently check this page for any changes to its Privacy Policy. Your continued use of this site after any change in this Privacy Policy will constitute your acceptance of such change.</p>

            <br/><br/>
            <h2>Credit & Contact Information</h2>
            <p>The Terms of Use and Privacy Policy information shown here were created at <a style={{ 'color': 'inherit', 'textDecoration': 'none' }} target="_blank" rel="noopener noreferrer" href="https://termsandconditionstemplate.com/privacy-policy-generator/" title="Privacy policy template generator">termsandconditionstemplate.com</a> and was adapted to this website. If you have any questions about this information, please contact us via <a href="&#109;&#97;&#105;&#108;&#116;&#111;&#58;&#111;&#112;&#101;&#110;&#114;&#101;&#97;&#115;&#115;&#101;&#109;&#98;&#108;&#121;&#64;&#99;&#103;&#118;&#46;&#116;&#117;&#103;&#114;&#97;&#122;&#46;&#97;&#116;">&#79;&#112;&#101;&#110;&#32;&#82;&#101;&#97;&#115;&#115;&#101;&#109;&#98;&#108;&#121;&#32;&#80;&#114;&#111;&#106;&#101;&#99;&#116;</a>.</p>
        </div>
    );
}

export default PrivacyPolicy;
