import Button from 'react-bootstrap/Button';
import { useNavigate, useParams, useLocation, Link } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { Col, Container, Row } from 'react-bootstrap';

import "./CommonForm.css"

function CreatedUser() {
    let navigation = useNavigate();
    let location = useLocation();

    if (location.state == null) {
        location.state = {displayName: "Test", id: "000000"};
    }

    function joinGame() {
        navigation("/Game/" + location.state.id, { state: location.state });
    }

    return (
        <div className='Container'>
            <h1 className='mb-5'>Benutzer erfolgreich erstellt</h1>
            <Container className='mb-5 Container w-100'>
                <Row className='row-cols-2 '>
                    <Col className='justify-content-end d-flex'><h3>Benutzername:</h3></Col>
                    <Col className='justify-content-start d-flex'><h3>{location.state.displayName}</h3></Col>
                    <Col className='justify-content-end d-flex'><h2>Benutzer ID:</h2></Col>
                    <Col className='justify-content-start d-flex'><h2>{location.state.id}</h2></Col>
                </Row>
                <Row>
                </Row>
            </Container>
            <div>
                Stelle sicher, dass du dir <b>deine ID merkst</b>, um in Zukunft da weiter zu machen wo du aufgehört hast.
                {/* Make sure to <b>write down your user id</b> to be able to connect in the future and continue working where you left off. */}
            </div>
            <div>
                Alternativ kannst du auch die <b><Link to={"/Game/" + location.state.id}>URL</Link> speichern</b> oder die Seite nach dem du den Knopf unten gedrückt hast <b>zu deinen Lesezeichen hinzufügen</b>.
                {/* Alternatively, you can <b>save the <Link to={"/Game/" + location.state.id}>url</Link></b> now or <b>bookmark the page</b> after you press the button below. */}
            </div>
            <div className='mt-5'>
                <h4><strong>Dem Spaß hier beitreten:</strong></h4>
            </div>
            <div className='w-100'>
                <Button onClick={joinGame} className='w-100'>Los Gehts!</Button>
            </div>
        </div>
    );
}

export default CreatedUser;