import logo from './logo.svg';
import './App.css';

import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom';

import Layout from "./pages/Layout"
import Welcome from "./pages/Welcome"
import SlabDetail from "./pages/SlabDetail"
import UserAccountEntryPoint from "./pages/UserAccountEntryPoint"
import NewUser from "./pages/NewUser"
import ExistingUser from "./pages/ExistingUser"
import CreatedUser from "./pages/CreatedUser"
import Game from "./pages/Game"
import Impressum from "./pages/Impressum"
import PrivacyPolicy from "./pages/PrivacyPolicy"

//https://www.w3schools.com/react/react_router.asp

function App() {
  return (
      <Router>
        <Routes>
          <Route path="/" element={<Layout/>}>
            <Route index element={<Welcome/>}/>
            <Route path="SlabDetail/:slabName" element={<SlabDetail/>}/>
            <Route path="Account" element={<UserAccountEntryPoint/>}/>
            <Route path='NewUser' element={<NewUser/>}/>
            <Route path='ExistingUser' element={<ExistingUser/>}/>
            <Route path='CreatedUser' element={<CreatedUser/>}/>
            <Route path='Game/:userid' element={<Game/>}/>
            <Route path='Impressum' element={<Impressum/>}/>
            <Route path='Privacy' element={<PrivacyPolicy/>}/>
          </Route>
        </Routes>
      </Router>  
  );
}

export default App;
