import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form'
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

import "./ExistingUser.css"
import "./CommonForm.css"

function ExistingUser() {
    let navigation = useNavigate();
 
    let [userID, setUserID] = useState("");
    let [waitingForCreation, setWaitingForCreation] = useState(false)

    function submit(e) {
        e.preventDefault();
        navigation("/Game/"+userID);
    }

    return (
        <div className='Container'>
            <h2>Bitte gib hier deine zugewiesene ID an:</h2>
            <Form onSubmit={e => submit(e)} className="Form">
                <Form.Group>
                    <Form.Label>User ID</Form.Label>
                    <Form.Control 
                        placeholder='Bitte ID eingeben:' 
                        value={userID} onChange={e => setUserID(e.target.value)}>
                    </Form.Control>                    
                </Form.Group>
                <Button className='SubmitButton' type='submit' variant='primary'>
                    {waitingForCreation ? "Waiting" : "Start"}
                </Button>
            </Form>
            <small>Für diese Applikation wird ein Desktop-Browser mit Maus und Tastatur benötigt.</small>
            <p>
                Hinweis: Sollte die Nachricht <i>Connecting to Server</i> länger erscheinen, versuche deinen Browser-Cache zu löschen, oder probiere es ein wenig später nocheinmal.                
            </p>
            
        </div>
    );
}

export default ExistingUser;