import { Outlet, Link, useNavigate, NavLink } from "react-router-dom";
import logo from '../logo.svg';
import './Layout.css';
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";

import Container from "react-bootstrap/Container";
import { Image, Stack, Row, Col, Dropdown } from "react-bootstrap";

import { FaAlignJustify } from "react-icons/fa6";

import useWindowWide from '../services/windowWide'

//import {ReactComponent as SteiermarkLogo} from '../logos/SteiermarkLogo.svg';
import SteiermarkLogo from '../logos/SteiermarkLogo_white.png';
import TUGrazLogo from '../logos/TU_Graz_white.png';
import UniGrazLogo from '../logos/Universität_Graz_logo.png';
import CGVLogo from '../logos/CGV-Logo_transparent-bg_280x240.png';

import { useState } from "react";

function Layout() {
    let navigate = useNavigate();

    const useWideVersion = useWindowWide(1000);

    let [contactEmail, setContactEmail] = useState("mailto:");

    const links = [
        ["/", "Start"], 
        ["/NewUser", "Neue Benutzer"], 
        ["/ExistingUser", "Bestehende Benutzer"],
        ["/SlabDetail/lavant", "Artefaktdetails"],
        ["/Privacy", "Nutzungsbedingungen"],
        ["/Impressum", "Impressum"]
    ];
        

    function getLargeMenu() {
        let contactLink = 
            <a className="ContactLink" 
                style={{"unicodeBidi": "bidi-override", direction: "rtl"}} 
                href={contactEmail} onMouseEnter={e => setContactEmail("mailto:openreassembly@cgv.tugraz.at") }>
                ta.zargut.vgc@ylbmessaernepo
            </a>;

        return (
            <div className="sticky-top flex flex-shrink-0 p-3 text-bg-dark" style={{ width: "250px", height: "100vh" }}>
                <a href="/" className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none">
                    <span className="fs-4">Open Reassembly</span>
                </a>
                <hr />
                <ul className="nav nav-pills flex-column mb-auto">
                    {
                        links.map(l => (
                            <li key={l[1]} className="nav-item">
                            <NavLink to={l[0]} className={({ isActive }) => (isActive? "active ": '') + "nav-link text-white"}>
                            {l[1]}
                            </NavLink>
                            </li>
                        ))
                    }
                </ul>
                <div className="SideLogoContainer">
                    <div>
                        <a href="https://www.tugraz.at/institute/cgv/home">
                            <img className="SideLogo" src={TUGrazLogo}></img>
                        </a>
                        <a href="https://antike.uni-graz.at/">
                            <img className="SideLogo" src={UniGrazLogo}></img>
                        </a>
                        <a href="https://www.wissenschaft.steiermark.at/">
                            <img className="SideLogo" src={SteiermarkLogo}></img>
                        </a>
                    </div>
                </div>
                <div className="SideContact">
                    <hr />
                    <div>
                            Kontakt<br />
                            <a className="ContactLink" href="www.cgv.tugraz.at">www.cgv.tugraz.at</a><br/>
                            {contactLink}
                    </div>
                </div>
            </div>
        );
    }

    function getSmallMenu() {
        return (
            <div className="sticky-top d-flex flex-shrink-0 p-3 text-bg-dark align-items-start justify-content-between" style={{ width: "100vw", height: "70px" }}>
                <NavLink to="/" className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none">
                    <span className="fs-4">Open Reassembly</span>
                </NavLink>
                <Dropdown className="">
                    <Dropdown.Toggle id="dropdown-basic" className="">
                    <FaAlignJustify></FaAlignJustify>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="text-bg-dark text-center p-2">
                        <div className="nav nav-pills flex-column">
                        {
                            links.map(l => (
                            <div key={l[1]} className="nav-item">
                                    <NavLink to={l[0]} className={({ isActive }) => (isActive? "active ": '') + "nav-link text-white"}>
                                        {l[1]}
                                    </NavLink>         
                            </div>
                            ))
                        }
                        </div>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        );
    }

    return (
        <div className="App">
            {/* <Navbar bg="dark" variant="dark" expand="lg">
                <Container>
                    <Navbar.Brand href="/">Open Reassembly</Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            <Nav.Link href="/">Home</Nav.Link>
                            <Nav.Link href="/NewUser">New User</Nav.Link>
                            <Nav.Link href="/ExistingUser">Existing User</Nav.Link>
                            <Nav.Link href="/Impressum">Impressum</Nav.Link>
                            <Nav.Link href="/Privacy">Privacy Policy</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar> */}
            <div className={"d-flex " + (useWideVersion ? "flex-row" : "flex-column")}>
                    {useWideVersion ? getLargeMenu() : getSmallMenu()}
                    <Col className="mt-3">
                        <Outlet></Outlet>
                    </Col>
            </div>
        </div>
    );
}

export default Layout;
