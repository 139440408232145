import axios from "axios";

//axios.defaults.baseURL = '${window.location.protocol}//${window.location.hostname}' + ':' + 3001;

const baseURL = window.location.protocol + "//" + (process.env.REACT_APP_BASE_URL || window.location.hostname) + '/api'; 
console.log("Axios Base Url: " + baseURL);

const api = axios.create({
    baseURL: baseURL,
});

export default api;