import Button from 'react-bootstrap/Button'
import Stack from 'react-bootstrap/Stack'
import Card from 'react-bootstrap/Card'
import Container from 'react-bootstrap/Container'
import Image from 'react-bootstrap/Image'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import './SlabDetail.css';

import { useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react';
import { Carousel, CarouselItem, Modal } from 'react-bootstrap';
import Markdown from 'react-markdown'
import useWindowWide from '../services/windowWide'

function SlabDetail() {
    let navigation = useNavigate()
    let { slabName } = useParams();
    let [slab, setSlab] = useState({title: "", quickinfosText: "", quickinfos: [], quicktipsText: "", quicktips:[], });

    let [showFullscreenImage, setShowFullscreenImage] = useState(false);
    let [fullscreenImage, setFullscreenImage] = useState("");


    useEffect(() => {
        fetch('/detail/' + slabName + '.json')
        .then(res => res.json())
        .then(res => setSlab(res));
    }, [slabName]);

    const useWideVersion = useWindowWide(1000);

    return (
        <Stack className='align-items-center d-flex mt-4 ms-5 me-5'>
            <h1 className='WelcomeHeader'>{slab.title}</h1>
            <Container className='justify-content-center align-items-center' direction={useWideVersion ? 'horizontal' : 'vertical'}>
                <Row>
                    <Col xs={12} lg={6} className='pt-2 pb-2'>
                        <h2>Quick Infos</h2>
                        <Markdown>{slab.quickinfosText}</Markdown>
                        <Carousel className='SlabCarousel' interval={showFullscreenImage ? null : 12000} slide={true} fade={true} touch={true}>
                            { slab.quickinfos.map(qt => createQuickTip(qt)) }
                        </Carousel>
                    </Col>
                    <Col xs={12} lg={6} className='pt-2 pb-2'>
                        <h2>Quick Tips</h2>
                        <Markdown>{slab.quicktipsText}</Markdown>
                        <Carousel className='SlabCarousel' interval={showFullscreenImage ? null : 12000} slide={true} fade={true} touch={true}>
                            { slab.quicktips.map(qt => createQuickTip(qt)) }
                        </Carousel>
                    </Col>
                </Row>
                <Modal className='FullscreenModal' show={showFullscreenImage && fullscreenImage != null} onHide={e => hideFullscreen()} onClick={e => hideFullscreen()}  fullscreen={true} animation={true} centered>
                    <Modal.Body className='ModalFullscreenBody'>
                        <div className='ModalContainer'>
                            <Image src={fullscreenImage.image} className='ModalFullscreenImage'></Image>
                        <p className='ModalCopyrightOverlay Copyright'>{fullscreenImage.copyright}</p>
                        </div>
                        {/* <p>{fullscreenImage.text}</p> */}
                    </Modal.Body>
                </Modal>
            </Container>
        </Stack>
    );

    function createQuickTip(entry) {
        return (
        <Carousel.Item key={entry.text}>
            <div className='CarouselItemContainer'>
                <Image src={entry.image} className='SlabCarouselImage' onClick={(s) => carouselImageClicked(entry)}/>
                <p className='CopyrightOverlay Copyright'>{entry.copyright}</p>
                <div className='Rect'></div>
            </div>
            <Carousel.Caption className='SlabCarouselCaption'>
                {entry.text}
            </Carousel.Caption>
        </Carousel.Item>
        );
    }

    function carouselImageClicked(e) 
    {
        setFullscreenImage(e);
        setShowFullscreenImage(true);
    }

    function hideFullscreen(e) 
    {
        setShowFullscreenImage(false);
    }

}

export default SlabDetail;