import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form'
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import api from '../services/api'

import "./CommonForm.css"
import "./ExistingUser.css"

function NewUser() {
    let navigation = useNavigate();
 
    let [userDisplayName, setUserDisplayName] = useState("");

    let [errorMessage, setErrorMessage] = useState('');
    
    let [tosCheckBoxChecked, setTosCheckBoxChecked] = useState(false); //initializes checkbox to false 
    
    const CheckboxWithButton = ({ /*label,*/ value, onChange }) => {
        return (
            <div>
            <label>
                <input type="checkbox" checked={value} onChange={onChange} />
                {/* i don't know how to pass a label string with HTML formatting... */}
                {/* {label} */}
                Ich habe die <a href="Privacy">Nutzungsbedinungen</a> gelesen und stimme ihnen zu.
            </label>
            <div className="centerElement">
            <Button id="conditionalSubmitButton" className='SubmitButton' type='submit' variant='primary' disabled={!value}>Erstellen</Button>
            </div>
            </div>
        );
    };


    function submit(e) {
        e.preventDefault();

        api.post('/User', {displayName: userDisplayName})
            .then(response =>  {
                console.log('Created User:');
                console.log(response)
                navigation("/CreatedUser/", { state: response.data });
            }).catch(reason =>  {
                console.log('Error in Creating User:');
                console.log(reason);
                var errorMessage = reason.response ? reason.response.data : 'Konnte User nicht erstellen.';
                if ( !userDisplayName ) { errorMessage = "Bitte gib einen Benutzernamen ein." }
                setErrorMessage(errorMessage);
            });
    }

    const handleChange = () => {
        setTosCheckBoxChecked(!tosCheckBoxChecked);
        // console.log( "HERE - setTosCheckBoxChecked changed!" );
    };
      
    return (
            <div className='Container'>
                <h2>Bitte gib deinen gewünschten Benutzernamen ein:</h2>
                <Form onSubmit={e => submit(e)} className="Form mt-5">
                    <Form.Group>
                        <Form.Control placeholder='Bitte Benutzernamen angeben' maxLength={20} value={userDisplayName} onChange={e => setUserDisplayName(e.target.value)}></Form.Control>                    
                    </Form.Group>
                    <CheckboxWithButton
                        //label={tosCheckBoxLabel}
                        value={tosCheckBoxChecked}
                        onChange={handleChange}
                    />
                </Form>
                <h5>{errorMessage.toString()}</h5>
                Für diese Applikation wird ein Desktop-Browser mit Maus und Tastatur benötigt.
                <p>
                Hinweis: Beim ersten Einstieg müssen einmalig 390 MB Spieldaten geladen werden, dies kann je nach Internetverbindung ein wenig dauern.<br/>
                Sollte die Nachricht <i>Connecting to Server</i> länger erscheinen, versuche deinen Browser-Cache zu löschen, oder probiere es ein wenig später nocheinmal.                
                </p>
            </div>
    );
  }
  
  export default NewUser;
