import Button from 'react-bootstrap/Button'
import Stack from 'react-bootstrap/Stack'
import './Welcome.css';


function Impressum() {
    return (
        <div className='Impressum'>
            <h1 className='mt-5'>Impressum</h1>
            <h3 className='mt-5 mb-3'>Projektinformation</h3>
            <p>
                Diese Webseite wurde im Rahmen des Forschungsprojekts <i>Open Reassembly</i> erstellt,<br/>
                welches vom <a href='https://www.wissenschaft.steiermark.at/'>Land Steiermark</a> im Rahmen der Ausschreibung<br/>
                <a href='https://www.wissenschaft.steiermark.at/cms/beitrag/12871433/144544138/'>UFO - Unkonventionelle Forschung 2022 (PN36)</a> gefördert wurde.
            </p>

            <h3 className='mt-5 mb-3'>Kontakt</h3>
            <p>
                Institut für Computer Graphik und Wissensvisualisierung<br/>
                Technische Universität Graz
            </p>
            <p>
                Inffeldgasse 16c<br/>
                8010 Graz, Austria<br/>
            </p>
            <p>
                <strong className='me-2'>Email:</strong>
                <a href="&#109;&#97;&#105;&#108;&#116;&#111;&#58;&#111;&#112;&#101;&#110;&#114;&#101;&#97;&#115;&#115;&#101;&#109;&#98;&#108;&#121;&#64;&#99;&#103;&#118;&#46;&#116;&#117;&#103;&#114;&#97;&#122;&#46;&#97;&#116;">&#79;&#112;&#101;&#110;&#32;&#82;&#101;&#97;&#115;&#115;&#101;&#109;&#98;&#108;&#121;&#32;&#80;&#114;&#111;&#106;&#101;&#99;&#116;</a>
            </p>

        </div>
    );
}

export default Impressum;
