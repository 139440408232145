import './Welcome.css';

import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react';
import Markdown from 'react-markdown'
import useWindowWide from '../services/windowWide';
import { Col, Row, Image, Container, Stack, Button } from 'react-bootstrap';

import SteiermarkLogo from '../logos/SteiermarkLogo.png';
import TUGrazLogo from '../logos/TU_Graz.png';
import UniGrazLogo from '../logos/Universität_Graz_logo.png';

function Welcome() {
    let navigation = useNavigate();
    let [welcomeText, setWelcomeText] = useState("");
    let [slabs, setSlabs] = useState([]);

    function navigate(path) {
        navigation(path);
    }

    useEffect(() =>  
    {
        fetch('welcome/definitions.json', {
            headers: {
              "Content-Type": "application/json",
              "Accept": "application/json",
            },
          })
        .then(res => res.json())
        .then(res => {
            setWelcomeTextFromPath(res.welcomeText);
            setSlabs(res.slabs)
        })
    }, []);

    function setWelcomeTextFromPath(path) 
    {
        fetch(path)
          .then(res => res.text())
          .then(res => setWelcomeText(res));
    };

    function slabClicked(slab) {
        if (slab.detail.length > 0) {
            navigation('/SlabDetail/' + slab.detail);
        }
    }

    const useWideVersion = useWindowWide(800);

    return (
        <div className='Welcome'>
            <Stack className='WelcomeStack'>
                <h1 className='WelcomeHeader mb-4'>Open Reassembly</h1>
                <Markdown className="WelcomeMarkdown">{welcomeText}</Markdown>
                <div className='d-flex flex-wrap justify-content-center'>
                    {slabs.map(slab => (<div key={slab.picture} className='ms-2 me-2'>{renderSlab(slab)}</div>) )}
                </div>

                {/* <Stack className='justify-content-center mt-5' direction={useWideVersion ? 'horizontal' : 'vertical'} gap={3}>
                    {slabs.map(slab => renderSlab(slab))}
                </Stack> */}
                <hr className='w-100 mt-5' ></hr>
                <div className='d-flex justify-content-center'>
                    <p style={{ width: "50%", textAlign: 'left' }}>Ein Projekt der Technischen Universität Graz und Universität Graz, gefördert durch das Land Steiermark</p>
                    <div className="FooterLogoContainer">
                        <div>
                            <a href="https://www.tugraz.at/institute/cgv/home">
                                <img className="FooterLogo" src={TUGrazLogo}></img>
                            </a>
                            <a href="https://antike.uni-graz.at/">
                                <img className="FooterLogo" src={UniGrazLogo}></img>
                            </a>
                            <a href="https://www.wissenschaft.steiermark.at/">
                                <img className="FooterLogo" src={SteiermarkLogo}></img>
                            </a>
                        </div>
                    </div>
                </div>
            </Stack>
        </div>
    );

    function renderSlab(slab) 
    {
        return (
            <div key={slab.title} >
                <div className='SlabImageContainer' onClick={() => slabClicked(slab)}>
                    <Image src={slab.picture} className='SlabImage'></Image>
                    <div className='Overlay mt-5'>  
                        <Markdown className='SlabOverlayText'>{slab.overlay}</Markdown>
                    </div>
                </div>
                <Markdown className='slabTitle' onClick={() => slabClicked(slab)}>{slab.title}</Markdown>
            </div>
        );
    }
}

export default Welcome;